.landingPage {
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  overflow-x: hidden !important;
  @screen lg {
    overflow-x: visible !important;
  }
  @screen xl {
    padding: 0;
    max-width: 1150px;
  }
}

.introSection {
  text-align: left;
  margin: 4.5em 0;
  @screen md {
    margin: 80px 0;
  }
  @screen lg {
    margin: 120px 0;
  }
}

.leader {
  margin-bottom: 4em;
  @screen md {
    margin-bottom: 8em;
  }
}

.leader > div {
  font-family: var(--font-lato);
  letter-spacing: -2.5px;
  font-size: 40px;
  line-height: 46px;
  font-weight: 300;
  margin-bottom: 30px;
  color: #207dca;
  width: 100%;
  white-space: nowrap;
  @screen sm {
    max-width: 500px;
    font-size: 40px;
    line-height: 46px;
  }
  @screen md {
    max-width: 500px;
    font-size: 62px;
    line-height: 72px;
  }
  @screen lg {
    font-size: 72px;
    line-height: 72px;
  }
}

.leader > div + span {
  display: block;
  font-family: var(--font-lato);
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 16px;
  text-align: left;
  color: var(--blue-100);
  @screen md {
    font-size: 36px;
    line-height: 42px;
  }
  @screen lg {
    font-size: 42px;
    line-height: 50px;
  }
}

.leader > div + span br {
  @screen sm {
    display: none;
  }
}

.metricHighlight {
  margin: 100px 0;
  @screen lg {
    margin: 140px 0;
  }
}

.metricHighlight > div {
}

.metricHighlight > div:nth-child(1) {
  font-family: var(--font-lato);
  font-size: 40px;
  line-height: 46px;
  font-weight: 300;
  letter-spacing: -0.25px;
  margin-bottom: 15px;
  text-align: center;
  color: #207dca;
  @screen md {
    font-size: 56px;
    line-height: 62px;
  }
  @screen lg {
    font-size: 96px;
    line-height: 96px;
  }
}

.metricHighlight > div:nth-child(2) {
  font-family: var(--font-lato);
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  color: #207dca;
  @screen md {
    font-size: 28px;
    line-height: 32px;
  }
  @screen lg {
    font-size: 32px;
    line-height: 38px;
  }
}

.blocks {
  display: flex;
  flex-direction: column;
  gap: 0;
  @screen sm {
    flex-direction: row;
    gap: 32px;
  }
}

.blocks .blocksInnerMobile {
  display: block;
  @screen lg {
    display: none;
  }
}

.blocks .blocksInnerDesktop {
  display: none;
  @screen lg {
    display: block;
  }
}

.block {
  text-align: center;
  flex: 1;
  padding: 16px 8px;
  border-radius: 10px;
  gap: 32px;
  flex-direction: column;
  margin-bottom: 20px;
  @screen lg {
    padding: 32px 0px;
    margin-bottom: 0px;
  }
}

.blocksHeading {
  font-family: var(--font-lato);
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  color: #207dca;
  @screen lg {
    font-size: 42px;
    line-height: 50px;
  }
}

.block .icon svg {
  margin: 0 auto;
  text-align: center;
  height: 64px;
  width: 64px;
  color: #207dca;
  margin-bottom: 30px;
}

.block .title {
  font-family: var(--font-lato);
  font-size: 18px;
  line-height: 21px;
  color: #207dca;
  font-weight: 600;
  margin-bottom: 16px;
  @screen md {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 28px;
  }
  @screen lg {
    font-size: 32px;
    line-height: 38px;
  }
}

.block .copy {
  color: #207dca;
  font-family: var(--font-roboto-regular);
  font-size: 14px;
  line-height: 19.6px;
  letter-spacing: 0px;
  margin: 0 auto;
  max-width: 240px;
  @screen md {
    max-width: 280px;
    font-size: 14px;
    line-height: 18px;
  }
  @screen lg {
    max-width: 340px;
    font-size: 16px;
    line-height: 24px;
  }
}

.blocksDecorated {
  flex-direction: column;
  gap: 24px;
  @screen md {
    gap: 18px;
    flex-direction: row;
  }
  @screen lg {
    gap: 32px;
    flex-direction: row;
  }
}

.blocksDecorated .block {
  text-align: left;
  padding-right: 75px;
  box-shadow: 0px 34px 54.900001525878906px 0px #260c0b26,
  0px 0px 9px 0px #0000000f;
  background: linear-gradient(165deg, rgba(255, 255, 255, 0) 14%, white 14%), url('/nft-snippet-3.jpg');
  background-repeat: no-repeat;
  padding: 72px 24px 48px;
  margin-bottom: 0;
  height: 300px;
  @screen xs {
    height: 310px;
  }
  @media screen and (min-width: 390px) and (max-width: 520px) {
    height: 280px;
  }
  @media screen and (min-width: 520px) and (max-width: 786px) {
    height: 240px;
  }
  @screen md {
    height: 360px;
    padding: 72px 32px 28px;
  }
  @media screen and (min-width: 900px) and (max-width: 1023px) {
    padding: 72px 28px 28px;
  }
  @screen lg {
    height: 380px;
    padding: 96px 48px 0px 48px;
  }
}

.blocksDecorated .block:nth-child(2),
.blocksDecorated ul li:nth-child(2) .block {
  background-image: linear-gradient(165deg, rgba(255, 255, 255, 0) 14%, white 14%), url('/nft-snippet-2.jpg');
}

.blocksDecorated .block:nth-child(3),
.blocksDecorated ul li:nth-child(3) .block {
  background-image: linear-gradient(165deg, rgba(255, 255, 255, 0) 14%, white 14%), url('/nft-snippet-1.jpg');
}

.blocksDecorated div.title {
  font-weight: 600;
}

.blocksDecorated div.copy {
  max-width: unset;
  margin: 0;
  @media screen and (min-width: 420px) and (max-width: calc(768px - 1px)) {
    font-size: 16px;
    line-height: 22px;
  }
  @media screen and (min-width: 900px) and (max-width: 1023px) {
    font-size: 16px;
    line-height: 22px;
  }
}

.blocksDesktop {
  display: none;
  @screen md {
    display: flex;
  }
}

.blocksMobile {
  display: block;
  @screen md {
    display: none;
  }
}

.spacer {
  margin: 40px 0 0;
  @screen sm {
    margin: 60px 0 0;
  }
  @screen md {
    margin: 80px 0 0;
  }
}
