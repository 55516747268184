.buttonTypeLink {
  display: inline-block;
  text-align: center;
}

.primaryButton,
.secondaryButton {
  font-family: var(--font-roboto-regular);
}

.primaryButtonDisabled,
.primaryButton:disabled,
.secondaryButtonDisabled,
.secondaryButton:disabled {
  cursor: not-allowed;
}

.primaryButton {
  background: var(--yellow-100);
  box-shadow: inset 0 0 0 1px var(--blue-100);
  color: var(--blue-100);
}
.primaryButton:hover {
  box-shadow: inset 0 0 0 2px var(--blue-100);
}
.primaryButton:active {
  background: var(--yellow-200);
  box-shadow: none;
}

.primaryButtonDisabled,
.primaryButtonDisabled:hover,
.primaryButton:disabled {
  background: var(--yellow-20);
  color: var(--gray-9);
  box-shadow: inset 0 0 0 1px var(--gray-9);
}

.secondaryButton {
  background: transparent;
  box-shadow: inset 0 0 0 1px var(--blue-100);
  color: var(--blue-100);
}
.secondaryButton:hover {
  box-shadow: inset 0 0 0 2px var(--blue-100);
}
.secondaryButton:active {
  background: var(--blue-1);
  box-shadow: none;
}

.secondaryButtonDisabled,
.secondaryButtonDisabled:hover,
.secondaryButton:disabled {
  background: none;
  color: var(--gray-9);
  box-shadow: inset 0 0 0 1px var(--gray-9);
}

.primaryButton svg,
.secondaryButton svg,
.primaryButton span,
.secondaryButton span {
  display: inline-block;
  height: 16px;
  width: 16px;
  vertical-align: middle;
  margin: 0 4px;
}
